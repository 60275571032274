export default [
    {
        path: '/available-trainings',
        name: 'available_trainings',
        component: () => import('@/views/for_student/trainings/available_trainings'),
        meta: {
            pageTitle: 'Available Trainings',
            breadcrumb: [
                { text: 'Available Trainings', active: true },
            ],
        },
    },
    {
        path: '/available-trainings/:id?',
        name: 'show_available_training',
        component: () => import('@/views/for_student/trainings/show'),
        meta: { pageTitle: 'Show Training', breadcrumb: [{ text: 'Available Trainings', to: '/available-trainings', }, { text: 'Show', active: true }] },
    },
    {
        path: '/my-trainings',
        name: 'my_trainings',
        component: () => import('@/views/for_student/trainings/my_trainings'),
        meta: {
            pageTitle: 'My Trainings',
            breadcrumb: [
                { text: 'My Trainings', active: true },
            ],
        },
    },
    {
        path: '/my-trainings/:id?',
        name: 'show_my_training',
        component: () => import('@/views/for_student/trainings/show'),
        meta: { pageTitle: 'Show Training', breadcrumb: [{ text: 'My Trainings', to: '/my-trainings', }, { text: 'Show', active: true }] },
    },
    {
        path: '/trainings/add',
        name: 'add_training',
        component: () => import('@/views/for_student/trainings/add'),
        meta: { pageTitle: 'Add Training', breadcrumb: [{ text: 'My Trainings', to: '/my-trainings', }, { text: 'Add', active: true }] },
    },

]
