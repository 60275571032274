export default [
    {
        path: '/grades_recheck',
        name: 'grades_recheck',
        component: () => import('@/views/for_student/grades_recheck/index'),
        meta: {
            pageTitle: 'grades_recheck',
            breadcrumb: [
                { text: 'grades_recheck', active: true },
            ],
        },
    },
    {
        path: '/grades_recheck/add',
        name: 'add_grades_recheck',
        permission: 'access_students_grades_recheck',
        component: () => import('@/views/for_student/grades_recheck/edit'),
        meta: { pageTitle: 'grades_recheck', breadcrumb: [{ to: '/grades_recheck', text: 'grades_recheck' }] },
    },
]
